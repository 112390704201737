import { useInView } from "framer-motion";
import { useRef } from "react";

function SplitText({children}){
    const container = useRef(null)
    const isInView = useInView(container,{ margin: "50px 0px 0px 0px"});
    return(
    <div ref={container} style={{overflow: 'hidden'}}>
        <div
        className="split-text"
        style={{
        transform: isInView ? "none" : "translateY(100px)",
        transition: "all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0s"
        }}
        >
        {children}
        </div>
    </div>
    )
};

export default SplitText;