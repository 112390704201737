import { useState, useEffect, useRef } from "react";
import { useInView } from "framer-motion";

function ShuffleText({ fixedText, text, velocity, id, className, animate = true }){
  const [shuffledText, setShuffledText] = useState(text);
  const textRef = useRef(null);
  const isInView = useInView(textRef, { margin: "50px 0px 0px 0px" });

  const shuffle = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  useEffect(() => {
    // console.log("Element is in view: ", isInView, text, animate)
    if (isInView && animate) {
      let currentText = text.split("");

      const repeatShuffle = (times) => {
        if (times === 0) {
          setShuffledText(text);
          return;
        }

        setTimeout(() => {
          currentText = shuffle(currentText);
          setShuffledText(currentText.join(""));
          repeatShuffle(times - 1);
        }, velocity);
      };

      repeatShuffle(text.length);
    };
  }, [isInView, text, velocity, animate]);

  return <p className={className} id={id} ref={textRef}>{fixedText} {shuffledText} </p>;
};

export default ShuffleText;
