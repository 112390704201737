import ShuffleText from "../../Animations/ShuffleText/ShuffleText";
import "./bannerimage.scss";

function BannerImage() {
  return (
    <div className="banner-image">
      
      <img
        src="./images/bannerimage/complete.png"
        alt="port"
        className="port"
      />
      <ShuffleText id="left-top" fixedText="■ 020" text="Container #223454 arriving" velocity={60} />
      <ShuffleText id="left-bottom" text="00:04:11 PM" velocity={100} />

      <ShuffleText id="right-top"  fixedText="■ 021" text="Container #223455 arriving" velocity={60} />
      <ShuffleText id="right-bottom" text="00:04:12 PM" velocity={100} />
    </div>
  );
}

export default BannerImage;
