export const servicesData = [
  {
    img: "./images/services/service.jpg",
    title: "Corporate AI Strategy Services",
    description:
      "As a company, we offer AI Corporate Strategy services designed to help businesses leverage the power of artificial intelligence (AI) to achieve their organizational goals and stay ahead in the competitive market.",
  },
  {
    img: "./images/services/service-two.png",
    title: "Specialized AI Development Services",
    description:
      "We also offer specialized AI development services that cater to the unique needs of our clients.",

  },
  {
    img: "./images/services/service-three.png",
    title: "Strategic Consulting and Industry Insights",
    description:
      "Our service is designed to help businesses navigate the complex and rapidly evolving landscape of AI and machine learning. We offer strategic guidance and advice to help businesses make informed decisions and stay ahead of the curve.",
  },

  {
    img: "./images/services/service-five.png",
    title: "Large-scale Data Labeling",
    description:
      "In addition, we also offer large-scale data labeling services that can help businesses generate high-quality datasets to train their machine learning models for a wide range of applications.",
  },
];

export const industriesData = [
  {
    tab: "Port Industry",
    data: {
      heading: "Port Industry",
      description:"Enhance your port operations with our Computer Vision technology that can detect, track, and monitor container movements, monitor vessel traffic, ensure compliance with safety regulations, prevent security threats, and more. Our solutions are designed to streamline your port operations and enable you to make data-driven decisions for improved performance and profitability.",
      useCases: [
        {
          heading: "Automated Information Extraction",
          description:"Capture and extract critical information related to container handling and transit. By automating manual repetitive tasks, eliminating errors, and enabling immediate processing of information, our solution empowers you to achieve unparalleled levels of efficiency and accuracy in your operations."
        },
        {
          heading: "Proximity Detection",
          description:"Detect the proximity of personnel to hazardous areas, such as the area underneath a suspended load, and alert workers to potential dangers. Furthermore, you can monitor the proximity of personnel to forklifts in hatch and on the wharf, while also detecting smoking in prohibited areas, ensuring compliance with safety regulations and preventing accidents and injuries."
        },
        {
          heading: "Tracking and Detection",
          description:"Detect whether personnel are wearing the necessary PPE gear, monitor for falls from height, and track containers. Furthermore, our solution can track containers and provide real-time updates on their location and status, enabling more efficient and streamlined operations in the port."
        },
      ],
      image:"./images/industries/industries.png",
    },
  },
  {
    tab: "Agriculture",
    data: {
      heading: "Agriculture ",
      description:
        "Transform your agricultural processes with our cutting-edge Computer Vision technology. Experience accurate crop monitoring, real-time disease detection, and automated harvesting. Our solutions empower you to optimize irrigation and fertilization, improve livestock monitoring, and make data-driven decisions for enhanced yields and resource efficiency. From precision agriculture to smart farming, our technology revolutionizes the way you grow and harvest, ensuring sustainable practices and increased profitability.",
      useCases: [
        {
          heading: "Crop monitoring",
          description:
            "Computer vision can analyze images of crops to determine their health and growth status. This allows you to identify areas that require more attention or fertilization, resulting in increased crop yields",
        },
        {
          heading: "Harvesting automation",
          description:
            "By integrating computer vision technology, agricultural machinery can be enhanced with camera systems, precisely identifying and harvesting mature crops. This results in time-saving efficiencies and minimizes waste by avoiding both over and under-harvesting scenarios.",
        },
        {
          heading: "Irrigation optimization",
          description:
            "By analyzing data from cameras and sensors, computer vision can provide you with real-time information on soil moisture levels, crop health, and weather conditions. This information can be used to optimize irrigation scheduling and reduce water waste, resulting in significant cost savings and improved crop yields.",
        },
      ],
      image:"./images/industries/industries-three.png",
    },
  },
  {
    tab: "Manufacturing",
    data: {
      heading: "Manufacturing",
      description:
        "Revolutionize your manufacturing operations with our advanced Computer Vision solutions. Seamlessly integrate our technology to automate quality control, detect defects, and optimize production lines. Enhance workplace safety with real-time monitoring, streamline logistics with automated tracking, and gain actionable insights to improve overall efficiency. Experience reduced downtime, minimized waste, and accelerated productivity, positioning your manufacturing business at the forefront of industry innovation. With our technology, you'll be empowered to meet market demands with precision and deliver high-quality products to drive success.",
      useCases: [
        {
          heading: "Quality control",
          description:
            "With computer vision, manufacturers can automate the inspection process and quickly identify defects or imperfections in products. This not only saves time but also helps ensure that only high-quality products are shipped to customers.",
        },
        {
          heading: "Predictive maintenance",
          description:
            "By analyzing data from sensors and cameras in real-time, computer vision can help manufacturers predict equipment failures before they occur. This allows for proactive maintenance, reducing downtime and preventing costly repairs.",
        },
        {
          heading: "Inventory management",
          description:
            "Computer vision can be used to automate inventory tracking and management, providing real-time data on stock levels and reducing the likelihood of stockouts or overstocking.",
        },
      ],
      image:"./images/industries/industries-two.png",
    },
  },
];
