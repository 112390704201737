import React from "react";
import "./card.scss";

function ThumbnailCard(props) {
  const { cardNo, img, title, description } = props;
  return (
    <figure className="thumbnail-card">
      <h3 className="card-no">0{cardNo}</h3>
      <picture>
        <img src={img} alt={title} />
      </picture>

      <figcaption>
        <h2 className="card-title">{title}</h2>
        <p className="description">{description}</p>
      </figcaption>
    </figure>
  );
}

export default ThumbnailCard;
