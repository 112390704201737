import React, { useState } from "react";
import { BsCheckLg } from "react-icons/bs";

import Banner from "../../components/Banner/Banner";
import Card from "../../components/Card/Card";
import ContactForm from "../../components/ContactForm/ContactForm";
import SectionWrapper from "../../components/SectionWrapper/SectionWrapper";
import { industriesData, servicesData } from "../../data/home";
import Monitor from "../../components/Monitor/Monitor";
import SplitText from "../../Animations/SplitText/SplitText";
import { TransitionImg } from "../../Animations/TransitionImg/TransitionImg";
import { useAnimate } from "framer-motion";

import "./home.scss";

const ContactDetails = () => {

  return (
    <article className="contant-details-wrapper container-fluid">
      <SectionWrapper
        sectionNo="5"
        subTitle="Contact Us"
        sectionID="contact"
        className="contact-details"
        title={
          <>
          <div>
            <SplitText>
            <span>B</span>ook a demo and
            </SplitText>
          </div>
          <div>
            <SplitText>
            discover the
            </SplitText>
          </div>
          <div>
            <SplitText>
            possibilites of MT.
            </SplitText>
          </div>
        </>
        }
      >
        <div className="section-content">
          <h3>
            Harness the benefits of our <br /> MT Product to:
          </h3>

          <ul>
            <li>
              <span className="icon">
                <BsCheckLg />
              </span>

              <p>Faster and simple process</p>
            </li>
            <li>
              <span className="icon">
                <BsCheckLg />
              </span>

              <p>Better products and services</p>
            </li>
            <li>
              <span className="icon">
                <BsCheckLg />
              </span>

              <p>Cost-reduction</p>
            </li>
            <li>
              <span className="icon">
                <BsCheckLg />
              </span>

              <p>A wide range of use</p>
            </li>
          </ul>
        </div>
      </SectionWrapper>

      <article className="contact-form">
        <p className="description">
        Don't miss the opportunity to see our MT© product in action! 
        Our team of experts is ready to work with you to understand your unique needs and demonstrate how our MT© product can help you overcome your specific challenges.
        </p>

        <ContactForm />
      </article>
    </article>
  );
};

const ProductBenefits = () => {
  return (
    <article className="product-benefits-details">
      <div className="product-benefits-image">
      </div>
      {/* <picture>
        <img src="./images/camera-image.jpg" alt="Product Image" />
      </picture> */}

      <SectionWrapper
        title={
          <>
          <div>
            <SplitText>
            <span>MT©</span> utilizes cutting-edge technology
            </SplitText>
          </div>
          <div>
            <SplitText>
            to craft Computer Vision systems that
            </SplitText>
          </div>
          <div>
            <SplitText>
            automate processes, reducing the time and 
            </SplitText>
          </div>
          <div>
            <SplitText>
            effort required to complete tasks and
            </SplitText>
          </div>
          <div>
            <SplitText>
            allowing your team to focus on other critical
            </SplitText>
          </div>
          <div>
            <SplitText>
            areas.
            </SplitText>
          </div>
        </>
        }
        bgDark={true}
        className="sme-section"
      >
        <article className="section-content">
          <article className="section-container">
            <h3 className="heading">
              Harness the benefits of our MT product to :
            </h3>

            <Card
              cardType="verticalCard"
              cardNo="1"
              title="Increase Business Efficiency"
              description="With the MT Product, you can optimize your 
              operations and improve efficiency, resulting in increased productivity, faster 
              turnaround times, and improved customer satisfaction."
            />
            <Card
              cardType="verticalCard"
              cardNo="2"
              title="Reduce Cost"
              description="By automating tasks and streamlining processes, the MT Product
              can help reduce costs associated with manual labor, equipment maintenance, 
              and other operational expenses."
            />
            <Card
              cardType="verticalCard"
              cardNo="3"
              title="Improve Human Safety"
              description="The MT Product can help improve safety in your workplace by identifying
              potential safety hazards before they become a problem, preventing accidents and ensuring a safer 
              work environment."
            />

            <figure className="announcement">
              <picture>
                <img src="./images/icon/logo_white.svg" alt="Logo Image" />
              </picture>

              <figcaption>
                <p className="description">
                Let us help you liberate your team's time, streamline your operations, and catapult your business to unparalleled success.
                </p>

                <a href="#contact" className="demo-btn">
                  {" "}
                  Book a Demo
                </a>
              </figcaption>
            </figure>
          </article>
        </article>
      </SectionWrapper>
    </article>
  );
};

const Monobotics = () => {
  return (
    <article className="monobotic-wrapper">
      <article className="monobotics-container">
        <h1>
          Monobotics technologies provides plug and play computers vision
          systems
        </h1>

        <article className="monobotic-details">
          <p>
            Why settle for standard when AI can offer so much more? 
            Discover the edge that sets industry leaders apart.
          </p>

          <a href="#contact">Book a Demo</a>
        </article>
      </article>
    </article>
  );
};

function Home() {
  const [serviceImage, setServiceImage] = useState(
    "./images/services/service.jpg"
  );
  const [tabs, setTabs] = useState("Port Industry");

  const industries = industriesData.filter((item) => item.tab == tabs);

  const [scope, animate] = useAnimate()

  const changeTabs = (tab) => {
    animate(scope.current, { opacity: 1 }, { duration: 1 });
    setTabs(tab);
  };


  const servicesImageChange = (data) => {
    setServiceImage(servicesData.filter((item) => item.img == data));
  };


  return (
    <main className="home-page">
      <Banner />
      <SectionWrapper
        sectionNo="1"
        subTitle="MT © Product"
        sectionID="product"
        title={
          <>
            <div>
              <SplitText>
                <span>O</span>ur (flagship) product is a web-based
              </SplitText>
            </div>
            <div>
              <SplitText>platform that allows crafting computer vision</SplitText>
            </div>
            <div>
              <SplitText>
                pipelines and deploying <span>computer vision</span>
              </SplitText>
            </div>
            <div>
              <SplitText>systems with ease.</SplitText>
            </div>
          </>
        }
      >
        <article className="our-product-wrapper">
          <article>
            <Card
              heading="Streamline operations"
              description="The MT© Platform utilizes cutting-edge technology to craft Computer Vision
systems that automate processes, reducing the time and effort required to
complete tasks and allowing your team to focus on other critical areas."
            />
            <Card
              heading="Increase business efficiency"
              description="With MT©, you can optimize your
operations and improve efficiency, resulting in increased productivity, faster
turnaround times, and improved customer satisfaction."
            />
            <Card
              heading="Reduce costs"
              description="By automating tasks and streamlining processes, the MT Product
can help reduce costs associated with manual labor, equipment maintenance,
and other operational expenses."
            />
            <Card
              heading="Improve human safety"
              description="The MT© Platform can help improve safety in your workplace by identifying
potential safety hazards before they become a problem, preventing accidents and ensuring a safer
work environment."
            />
          </article>
        </article>
      </SectionWrapper>

      <SectionWrapper
        bgDark={true}
        className="about-ai"
        title={
          <>
          <div>
            <SplitText>
            <span>W</span>e believe AI is the driving force  
            </SplitText>
          </div>
          <div>
            <SplitText>
            behind {" "} <span> industry growth</span>, giving businesses of all sizes 
            </SplitText>
          </div>
          
          <div>
            <SplitText>
            the competitive edge they need to thrive.
            </SplitText>
          </div>
        </>
        }
      >
        <article className="section-content">
          <a href="#contact" className="Demo-btn">
            Book a Demo
          </a>
          <img
            src="./images/ai-image.png"
            alt="AI Image"
            className="ai-image"
          />
        </article>
      </SectionWrapper>

      <SectionWrapper
        bgDark={true}
        className="power-of-ai"
        title={
        <>
          <div>
            <SplitText>
            <span>Y</span>ou can do much more with   
            </SplitText>
          </div>
          <div>
            <SplitText>
            standard security cameras. Transform passive footage 
            </SplitText>
          </div>
          <div>
            <SplitText>
            into <span>actionable intelligence</span> with AI.
            </SplitText>
          </div>
        </>
        }
      >
        <article className="section-content">
          <Card
            bgDark={true}
            cardNo="1"
            heading="Flow Analytics"
            description="Capture and analyze the movement and interactions within a space or system, providing insights into efficiencies, bottlenecks, and optimization opportunities"
          />
          <Card
            bgDark={true}
            cardNo="2"
            heading="Automated Asset Tracking"
            description="You can utilize real-time tracking of equipment, products, or any valuable asset, reducing misplacements and ensuring optimal utilization."
          />
          <Card
            bgDark={true}
            cardNo="3"
            heading="Defects Detection and Inspection"
            description="Harness AI's precision in scanning for inconsistencies, defects, or quality issues, ensuring the highest standards and reducing manual inspection efforts."
          />
          <Card
            bgDark={true}
            cardNo="4"
            heading="Automated Information Extraction"
            description="Swiftly and accurately pull out essential data from your camera streams, streamlining processes and reducing manual data entry errors."
          />
          <Card
            bgDark={true}
            cardNo="5"
            heading="Inventory Management and Statistics"
            description="Automate the monitoring and cataloging of stock levels, offering real-time statistics, ensuring adequate supply, and aiding in demand forecasting."
          />
          <Card
            bgDark={true}
            cardNo="6"
            heading="Predictive Maintenance Alerts"
            description="Analyze equipment performance data, sending timely alerts for potential malfunctions or wear, reducing unexpected downtimes and costly repairs."
          />
        </article>
      </SectionWrapper>

      <Monitor/>


      <SectionWrapper
        sectionNo="2"
        subTitle="Industries"
        sectionID="industries"
        className="industries-section"
        title={
        <>
          <div>
            <SplitText>
            <span>W</span>e empower SMEs in various  
            </SplitText>
          </div>
          <div>
            <SplitText>
            <span>industries</span> to use the power of AI to harness 
            </SplitText>
          </div>
          <div>
            <SplitText>
            their full potential.
            </SplitText>
          </div>
        </>
        }
      >
        <article className="section-content" ref={scope}>
          {industries.map((industry, index) => {
            // console.log(industries);
            const { data } = industry;
            const { heading, description, useCases, image } = data;

            return (
              <article className="tabs-data" key={index}>
                <article className="industries-wrapper">
                  <article className="about-indistry">
                    <h3>{heading}</h3>
                    <p>{description}</p>
                  </article>

                  <article className="use-cases-wrapper">
                    <h3>Typical Use Case :</h3>

                    <article className="use-cases">
                      <div className="accordion" id="accordionExample">
                        {useCases.map((useCase, index) => {
                          const { heading, description } = useCase;
                          return (
                            <div className="accordion-item" key={index}>
                              <h2
                                className="accordion-header"
                                id={`heading${index}`}
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#collapse${index}`}
                                  aria-expanded="true"
                                  aria-controls={`collapse${index}`}
                                >
                                  {heading}
                                </button>
                              </h2>
                              <div
                                id={`collapse${index}`}
                                className="accordion-collapse collapse"
                                aria-labelledby={`heading${index}`}
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                  {description}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      <a href="#contact" className="demo-btn">
                        Book a Demo
                      </a>
                    </article>
                  </article>
                </article>

                <article className="industries-carousel">
                  <div
                    id={`carouselExampleIndicators${index}`}
                    className="carousel slide"
                  >
                    <div className="carousel-inner">
                          <div className="carousel-item active" key={index}>
                            <img
                              src={image}
                              className="d-block w-100"
                              alt="..."
                            />
                          </div>
                    </div>
                  </div>
                </article>
              </article>
            );
          })}

          <article className="tabs-wrapper">
            <article className="tabs">
              {industriesData.map((industry, index) => {
                const { tab } = industry;
                return (
                  <h2
                    className={tabs == tab ? "tab-active" : ""}
                    key={index}
                    onClick={() => changeTabs(tab)}
                  >
                    {tab}
                  </h2>
                );
              })}
            </article>
          </article>
        </article>
      </SectionWrapper>

      <SectionWrapper
        bgDark={true}
        sectionNo="3"
        subTitle="Services"
        sectionID="services"
        className="services"
        title={
          <>
          <div>
            <SplitText>
            <span>Y</span>our business is unique, and so should be your AI solutions.  
            </SplitText>
          </div>
          <div>
            <SplitText>
            Discover <span>tailor-made solutions</span> crafted to elevate every 
            </SplitText>
          </div>
          <div>
            <SplitText>
            facet of your business.
            </SplitText>
          </div>
        </>
        }
      >
        <article className="section-content">
          <div className="services-image">
            <TransitionImg serviceImage={serviceImage[0].img ? serviceImage[0].img : serviceImage}/>
          </div>

          <article className="services-details">
            <h3 className="heading">Our Services</h3>

            <article className="services-accordian">
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                {servicesData.map((service, index) => {
                  const { title, description, img } = service;
                  return (
                    <div className="accordion-item" key={index}>
                      <h2
                        className="accordion-header"
                        id={`flush-heading${index}`}
                        onClick={() => servicesImageChange(img)}
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#flush-collapse${index}`}
                          aria-expanded="false"
                          aria-controls={`flush-collapse${index}`}
                        >
                          {title}
                        </button>
                      </h2>
                      <div
                        id={`flush-collapse${index}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`flush-heading${index}`}
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          {description}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <a href="#contact" className="contact-btn">
                Contact Us
              </a>
            </article>
          </article>
        </article>
      </SectionWrapper>

      <Monobotics />

      <SectionWrapper
        sectionNo="4"
        subTitle="Implementation"
        sectionID="implementation"
        className="implementation-section"
        title={
          
          <>
          <div>
            <SplitText>
            <span>W</span>ith our product you can take advantage of
            </SplitText>
          </div>
          <div>
            <SplitText>
            the latest AI technologies.
            </SplitText>
          </div>
        </>
        }
      >
        <article className="section-content">
          <Card
            cardType="thumbnailCard"
            cardNo="1"
            img="./images/implementation/imp-image.png"
            title="Solution Scoping"
            description="At Monobotics, we follow a meticulous approach to implementing our cutting-edge computer vision solutions. The first step begins with a thorough assessment of your unique requirements and objectives. Our expert team collaborates closely with you to understand your specific needs and pain points. "
          />
          <Card
            cardType="thumbnailCard"
            cardNo="2"
            img="./images/implementation/imp-image-2.png"
            title="Integration"
            description="Seamless integration is at the core of our implementation process. Depending on your current setup, we offer two options: First, we can integrate our advanced computer vision technology with your existing camera infrastructure, leveraging your current investment to enhance capabilities. Alternatively, if required, our dedicated team can install new, state-of-the-art hardware that perfectly complements the computer vision solution. Our team ensures a smooth and efficient integration, minimizing disruption to your operations while maximizing the system's potential to deliver tangible results."
          />
          <Card
            cardType="thumbnailShuffleCard"
            cardNo="3"
            img="./images/implementation/imp-image-3.png"
            title="Delivery"
            description="Our commitment to providing exceptional service continues with the delivery phase. We ensure that all components of the computer vision solution are configured and tested meticulously, guaranteeing optimal performance and accuracy. Our team conducts comprehensive training sessions, equipping your staff with the knowledge and skills to operate the system effectively. Throughout the delivery process, we offer continuous support and assistance to ensure a successful and seamless transition to our computer vision technology."
          />
        </article>
      </SectionWrapper>

      <ProductBenefits />
      <ContactDetails />
    </main>
  );
}

export default Home;
