import React, { useState } from "react";
import { BsX, BsFilterLeft } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useScroll, useMotionValueEvent, motion } from "framer-motion";

import { menus } from "../../data/navbar";
import "./navbar.scss";

const invisible = {  visibility: "hidden",
  opacity: "0",
  transition: "visibility 0s linear 300ms, opacity 300ms"};

const visible = {  visibility: "visible",
opacity: "1",
transition: "visibility 0s linear 0s, opacity 300ms"};

const renderMenuItems = (item, idx) => {
  const urls = `#${item.url}`
  const returnUrl =  window.location.pathname === '/privacy-policy' ? '/' : urls

  //no animation on master whilehover
  const master = {
    hovering: {}
  }

  const h3animation = {
    hovering: { y: "100%"}
  }

  const h2animation = {
    hovering: { y: "-100%" }
  }

  return (
    <li className="menu-item" key={idx}>
      <motion.a 
      href={returnUrl}
      whileHover="hovering"
      variants={master}
      >
        <motion.h3 variants={h3animation} transition={{ ease: [0.17, 0.55, 0.55, 1] }}>0{idx + 1}</motion.h3>
        <motion.h2 variants={h2animation} transition={{ ease: [0.17, 0.55, 0.55, 1] }}>{item.linkText}</motion.h2>
      </motion.a>
    </li>
  )
}

const Menubar = (props) => {
  const { isMenuActive, setMenuActive,isRedirectUrl } = props;
  // console.log('isRedirectUrl', isRedirectUrl)
  return (
    <ul className={isMenuActive ? "menus-wrapper active" : "menus-wrapper"}>
      <span className="cross-icon" onClick={() => setMenuActive(false)}>
        <BsX />
      </span>

      {menus.map((menu, index) => (
        renderMenuItems(menu,index,isRedirectUrl)
      ))}
    </ul>
  );
};

function Navbar() {
  const [isMenuActive, setMenuActive] = useState(false);
  const [latestScrollPos, setLatestScrollPos] = useState(0);
  const [showNav, setShowNav] = useState(true);
  const location = window.location.pathname;

  const { scrollY } = useScroll();

  useMotionValueEvent(scrollY, "change", (latest) => {
    if(latest > latestScrollPos){
      setShowNav(false);
    }
    else{
      setShowNav(true);
    };
    setLatestScrollPos(latest);
    // console.log("Page scroll: ", latest)
  });

  return (
    <nav
    style={showNav ? visible : invisible}
    >
      <article className="container-fluid d-flex align-items-center justify-content-between">
        <Link to="/" className="logo">
          <img
            src="./images/logo.png"
            alt="site logo"
            className="image-fluid"
            style={{width: '11em'}}
          />
        </Link>

        <article className="navbar-right">
          <Menubar isMenuActive={isMenuActive} setMenuActive={setMenuActive} isRedirectUrl={location} />
          <article className="demo-btn-wrapper">
            <a href="#contact" className="demo-btn">
              Book A Demo
            </a>
            <span className="menu-icon" onClick={() => setMenuActive(true)}>
              <BsFilterLeft />
            </span>
          </article>
        </article>
      </article>
    </nav>
  );
}

export default Navbar;
