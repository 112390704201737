import "./monitor.scss";
import {
  motion,
  useScroll,
  useMotionValueEvent,
  useTransform,
  useMotionTemplate,
} from "framer-motion";
import { useRef, useEffect, useState } from "react";
import MonitorText from "./MonitorText";

function Monitor() {
  const [displayShuffle, setDisplayShuffle] = useState(false);

  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end end"],
  });

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    if (latest >= 0.5) {
      setDisplayShuffle(true);
    }
    else{
      setDisplayShuffle(false);
    }
  });

  const pathX = useTransform(scrollYProgress, [0, 0.5, 1], [15, 50, 50]);
  const pathY = useTransform(scrollYProgress, [0, 0.5, 1], [35, 50, 50]);

  const squaresize = useMotionTemplate`inset(calc(50vh - ${pathY}vh + 23px) calc(50vw - ${pathX}vw + 23px))`;

  return (
    <article className="section-content monitor" ref={ref}>
      {/* <div className="monitor"></div> */}
      <motion.div
        className="monitor-background"
        style={{ clipPath: squaresize }}
      ></motion.div>
      <MonitorText displayShuffle={displayShuffle}/>
    </article>
  );
}

export default Monitor;
