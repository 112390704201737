import React from "react";
import { Link } from "react-router-dom";

import "./page404.scss";

function Page404() {
  return (
    <article className="page-404">
      <article className="text">
        <img src="./images/icon/logo.svg" alt="" className="icon" />
        <p className="text">
          <span>404</span> This page could not be found
        </p>
      </article>
      <Link to="/" className="go-back-button">
        Go back to Homepage
      </Link>
    </article>
  );
}

export default Page404;
