import React from "react";
import "./cameraBox.scss";

function CameraBox() {
  return <article>
    <div className="box box1"></div>
    <div className="box box2"></div>
    <div className="box box3"></div>
    <div className="box box4"></div>
    <div className="line line1"></div>
    <div className="line line2"></div>
    <div className="cross x-cross"></div>
    <div className="cross y-cross"></div>
  </article>;
}

export default CameraBox;
