import React from "react";

function VerticalCard(props) {
  const { cardNo, title, description } = props;
  return (
    <figure className="vertical-card">
      <span className="card-no">0{cardNo}</span>
      <figcaption>
        <h3 className="title">{title}</h3>
        <p className="description">{description}</p>
      </figcaption>
    </figure>
  );
}

export default VerticalCard;
