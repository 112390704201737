export const privacies = [
  {
    heading: "Introduction and Overview",
    description: [
      "If you still have any questions or concerns, please contact us at info@monobotics.tech.",
    ],
  },
  {
    heading: "SUMMARY OF KEY POINTS",
    description: [
      "____",
    ],
  },
  {
    heading: "Introduction and Overview",
    description: [
      "",
      "",
    ],
  },
  {
    heading: "Scope of application",
    description: [
      "",
    ],
  },
  {
    heading: "Security",
    description: [
      "",
    ],
  },
  {
    heading: "Data protection",
    description: [
      "",
    ],
  },
];
