import React from "react";
import "./banner.scss";
import SplitText from "../../Animations/SplitText/SplitText";
import BannerImage from "../BannerImage/BannerImage";

function Banner() {
  return (
    <header className="banner-wrapper container-fluid">
      <h1>
        <SplitText>
          <span>A</span>ffordable and ready to <br />
        </SplitText>
        <SplitText>
          use, real-time intelligence{" "}
        </SplitText>
        <SplitText>
          to <span>work smarter</span>.
        </SplitText>
      </h1>

      <article className="banner-details">
        <p className="banner-text">
          Monobotics technologies provides plug and play computer vision systems
          to revolutionize industries and provide a competitive edge to
          businesses of all sizes. Leverage the power of AI to disrupt traditional 
          processes, supercharge efficiency, and unlock unprecedented growth potentials.
        </p>
        <BannerImage/>
      </article>
    </header>
  );
}

export default Banner;
