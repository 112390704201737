import React from "react";
import { Link, redirect } from "react-router-dom";
import "./footer.scss";

function Footer() {
  const redirectPage = () => {
    const urlOrigin = window.location.origin
    window.location.href = `${urlOrigin}/privacy-policy`
  }

  return (
    <footer>
      <article className="container">
        <article className="footer-top">
          <h1>
            <span>H</span>arness the power of <br />{" "}
            <span>computer vision systems</span>
          </h1>

          <a href="#contact">Book a Demo</a>
        </article>

        <article className="footer-center">
          <article className="footer-details">
            <h3>Monobotics Technologies</h3>
            <p>E: info@monobotics.tech</p>
            <p>T 1: +(39) 340 93 94 261</p>
            <p>T 2: +(49) 015775 606 353</p>
          </article>

          <article className="footer-links">
            <h3>Company</h3>

            <ul>
              <li>
                <a href="#product">MT Products</a>
              </li>
              <li>
                <a href="#industries">Industries</a>
              </li>
              <li>
                <a href="#services">Services</a>
              </li>
              <li>
                <a href="#implementation">Implementation</a>
              </li>
              <li>
                <a href="#contact">Contact Us</a>
              </li>
            </ul>
          </article>

          <article className="footer-links">
            <h3>Social</h3>

            <ul>
              <li>
                <a href="https://www.linkedin.com/company/monobotics/about/">Linkedin</a>
              </li>
              <li>
                <a href="https://twitter.com/Monobotics_tech">Twitter / X</a>
              </li>
            </ul>
          </article>
        </article>

        <div className="fitd">
            <img src="./images/fitd.png" className="fitd-image" alt="Fitd Logo" />
        </div>

        <article className="footer-bottom">
          <picture>
            <img src="./images/icon/logo_white.svg" alt="Site Logo" />
          </picture>

          <p>
            <span>© 2023 Monobotics Technologies | </span>{" "}
            <Link onClick={redirectPage}>Privacy Policy</Link>
          </p>
        </article>
      </article>
    </footer>
  );
}

export default Footer;
