import React from "react";
import "./input.scss";

function Input(props) {
  const { label, type, placeholder, value, name, onChange, children } = props;
  return (
    <article className="input-wrapper">
      <label>{label}</label>
      {!children
        ? type && (
            <input
              type={type}
              placeholder={placeholder}
              className="custom-input"
              value={value}
              name={name}
              onChange={onChange}
            />
          )
        : children}
    </article>
  );
}

export default Input;
