import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import CameraBox from "./components/CameraBox/CameraBox";
import Layout from "./components/Layout/Layout";
import Home from "./pages/Home/Home";
import Page404 from "./pages/Page404/Page404";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  return (
    <main>
      <ScrollToTop />
      <CameraBox />
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" exact element={<Home />} />
          <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
        </Route>
        <Route path="*" element={<Page404 />} />
      </Routes>
    </main>
  );
}

export default App;
