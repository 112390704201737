import React from "react";
import "./sectionWrapper.scss";

function SectionWrapper(props) {
  const { subTitle, title, sectionNo, children, className, bgDark, sectionID } =
    props;
  return (
    <article
      className={
        bgDark
          ? `section-wrapper dark-bg  ${className}`
          : `section-wrapper ${className}`
      }
    >
      <article className="container-fluid">
        {subTitle && (
          <h3
            id={sectionID}
            className={bgDark ? "subtitle color-light" : "subtitle"}
          >
            <span>0{sectionNo}</span> {subTitle}
          </h3>
        )}
        <h1 className={bgDark ? "title color-light" : "title"}>
            {title}
        </h1>

        {children}
      </article>
    </article>
  );
}

export default SectionWrapper;
