import React, { useRef, useState } from 'react';

import emailjs from '@emailjs/browser';
import Input from "../Input/Input";

import "./contactForm.scss";

function ContactForm() {
  const form = useRef();
  const [isSubmit, setIsSubmit] = useState(false);

  // State variables for input fields
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [message, setMessage] = useState('');


  const sendEmail = (e) => {
    e.preventDefault();

    // Check if any of the required fields are empty
    if (!name || !email || !number) {
      alert('Please fill out all required fields (Name, Email, and phone number)');
      return;
    }

    const formData = {
      name,
      email,
      number,
      text: companyName,
      message,
    };

    emailjs.sendForm('monobotics-smtp', 'template_osjfomd', form.current, 'gjeiFFYckpqPNssqs')
      .then((result) => {
        console.log(result.text);
        e.target.reset();
        setTimeout(() => {

          setIsSubmit(true);
        }, 3000);
      }, (error) => {
        console.log(error.text);
        setIsSubmit(false);
      });
  };


  return (
    <form ref={form} onSubmit={sendEmail}>
      {isSubmit ? <h2>Successfully Submitted</h2> : ''}
      <Input
        label="First and Last Name"
        placeholder="John Doe"
        type="text"
        name="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Input
        label="Email Address"
        placeholder="name@domain.com"
        type="email"
        name="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Input
        label="Phone Number"
        placeholder="+389 75 234 432"
        type="number"
        name="number"
        value={number}
        onChange={(e) => setNumber(e.target.value)}
      />
      <Input
        label="Company Name"
        placeholder="Farrell Group LLC"
        type="text"
        name="text"
        value={companyName}
        onChange={(e) => setCompanyName(e.target.value)}
      />

      <Input label="Description">
        <textarea
          rows="5"
          columns="10"
          className="custom-input"
          placeholder="Type here.."
          name="message"

          value={message}
          onChange={(e) => setMessage(e.target.value)}

        ></textarea>
      </Input>

      <article className="privacy-policy">
        <input type="checkbox" className="checkbox" />

        By sending the message I agree to the <a href="http://monobotics.tech/privacy-policy">Privacy Policy.</a>
      </article>

      <button>Send A Message</button>
    </form>
  );
}


export default ContactForm;