import React from "react";
import ShuffleText from "../../Animations/ShuffleText/ShuffleText";
import "./card.scss";

function ThumbnailShuffleCard(props) {
  const { cardNo, img, title, description } = props;
  return (
    <figure className="thumbnail-card shuffle-card">
      <h3 className="card-no">0{cardNo}</h3>
      <div>
        <img src={img} alt={title} />
        <ShuffleText className="shuffle" fixedText="■ 072" text="Person Helmet On 0.97%" velocity={100}/>
        <ShuffleText className="shuffle bottom" text="00:12:44 PM" velocity={100}/>
      </div>

      <figcaption>
        <h2 className="card-title">{title}</h2>
        <p className="description">{description}</p>
      </figcaption>
    </figure>
  );
}

export default ThumbnailShuffleCard;
