import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

import "./transitionimg.scss";

const variants = {
    enter: (dir) => {
      return {
        x: -1000,
        opacity: 0
      };
    },
    center: {
      zIndex: "auto",
      x: 0,
      opacity: 1
    },
    exit: (dir) => {
      return {
        zIndex: 0,
        x: 1000,
        opacity: 0
      };
    }
  };

export const TransitionImg = (serviceImage) => {
    const [imageUrl, setImageUrl] = useState(serviceImage.serviceImage);
    const [dir, setDir] = useState(1);

    useEffect(()=>{
        setImageUrl(serviceImage.serviceImage)
        setDir(dir+1)
    },[serviceImage.serviceImage]);
    return (
      <>
        <AnimatePresence initial={false} custom={dir}>
          <motion.img
            key={dir}
            src={imageUrl}
            alt="service image"
            custom={dir}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 200, damping: 30 },
              opacity: { duration: 0.2 }
            }}
          />
        </AnimatePresence>
      </>
    );
  };

