import React from "react";
import { privacies } from "../../data/privacy";

import "./privacyPolicy.scss";

function PrivacyPolicy() {
  return (
    <section className="privacy-policy container">
      <h1 className="title">
        <span>P</span>rivacy <span>Policy</span>
      </h1>

      <article className="content-wrapper">
        <h2 className="date">
          <span>Updated :</span> 21 February, 2023
        </h2>

        <article className="description">
          {privacies.map((privacy, index) => {
            const { heading, description } = privacy;
            return (
              <article className="privacy">
                <h1 className="heading">{heading}</h1>

                {description.map((des, index) => (
                  <p>{des}</p>
                ))}
              </article>
            );
          })}
        </article>
      </article>
    </section>
  );
}

export default PrivacyPolicy;
