import React from "react";
import HorizontalCard from "./HorizontalCard";
import ThumbnailCard from "./ThumbnailCard";
import ThumbnailShuffleCard from "./ThumbnailShuffleCard";
import VerticalCard from "./VerticalCard";

function Card(props) {
  const { cardType } = props;

  switch (cardType) {
    case "horizontalCard":
      return <HorizontalCard {...props} />;

    case "thumbnailCard":
      return <ThumbnailCard {...props} />;

    case "thumbnailShuffleCard":
      return <ThumbnailShuffleCard {...props} />;

    case "verticalCard":
      return <VerticalCard {...props} />;

    default:
      return <HorizontalCard {...props} />;
  }
}

export default Card;
