import React from "react";
import "./card.scss";

function HorizontalCard(props) {
  const { cardNo, heading, description, bgDark } = props;
  return (
    <figure
      className={bgDark ? "horizontal-card color-light " : "horizontal-card"}
    >
      {cardNo && <span className="card-no">0{cardNo}</span>}
      <h2 className="heading">{heading}</h2>
      <p className="description">{description}</p>
    </figure>
  );
}

export default HorizontalCard;
