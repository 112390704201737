import { useState, useEffect } from "react";
import ShuffleText from "../../Animations/ShuffleText/ShuffleText";

const invisible = {  visibility: "hidden",
opacity: "0",
transition: "visibility 0s linear 300ms, opacity 300ms"};

const visible = {  visibility: "visible",
opacity: "1",
transition: "visibility 0s linear 0s, opacity 300ms"};

export default function MonitorText({displayShuffle}) {
  const [animate, setAnimate] = useState(false);

  useEffect(()=>{
    setAnimate(displayShuffle);
  },[displayShuffle]);

  return (
    <div
      className="shuffle-container"
      style={ displayShuffle ? visible : invisible}
    >
      <img className="monitor-frame" src="./images/frame.svg"/>
      <div className="shuffle-text shuffle-monitor1">
        <ShuffleText fixedText="■ 001" text="Tracking" velocity={150} animate={animate} />
        <ShuffleText className="bottom" text="MT.22:12:24" velocity={150} animate={animate} />
      </div>
      <div className="shuffle-text shuffle-monitor2">
        <ShuffleText fixedText="■ 014" text="Аutomation" velocity={150} animate={animate} />
        <ShuffleText className="bottom" text="00:03:22" velocity={150} animate={animate} />
      </div>
      <div className="shuffle-text shuffle-monitor3">
        <ShuffleText fixedText="■ 004" text="Analyzing" velocity={150} animate={animate} />
        <ShuffleText className="bottom" text="00:02:55" velocity={150} animate={animate} />
      </div>
      <div className="shuffle-text shuffle-monitor4">
        <ShuffleText fixedText="■ 007" text="Container" velocity={150} animate={animate} />
        <ShuffleText className="bottom" text="00:02:55" velocity={150} animate={animate} />
      </div>
      <div className="shuffle-text shuffle-monitor5">
        <ShuffleText fixedText="■ 024" text="Handling" velocity={150} animate={animate} />
        <ShuffleText className="bottom" text="00:10:24" velocity={150} animate={animate} />
      </div>
      <div className="shuffle-text shuffle-monitor6">
        <ShuffleText fixedText="■ 001" text="Processing" velocity={150} animate={animate} />
        <ShuffleText className="bottom" text="MT.22:12:24" velocity={150} animate={animate} />
      </div>
      <div className="shuffle-text shuffle-monitor7">
        <ShuffleText fixedText="■ 014" text="Аutomation" velocity={150} animate={animate} />
        <ShuffleText className="bottom" text="00:03:22" velocity={150} animate={animate} />
      </div>
      <div className="shuffle-text shuffle-monitor8">
        <ShuffleText fixedText="■ 004" text="Vehicle" velocity={150} animate={animate} />
        <ShuffleText className="bottom" text="00:02:55" velocity={150} animate={animate} />
      </div>
      <div className="shuffle-text shuffle-monitor9">
        <ShuffleText fixedText="■ 007" text="Analyzing" velocity={150} animate={animate} />
        <ShuffleText className="bottom" text="00:02:55" velocity={150} animate={animate} />
      </div>
      <div className="shuffle-text shuffle-monitor10">
        <ShuffleText fixedText="■ 024" text="Handling" velocity={150} animate={animate} />
        <ShuffleText className="bottom" text="00:10:24" velocity={150} animate={animate} />
      </div>
    </div>
  );
}
