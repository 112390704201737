export const menus = [
  {
    linkText: "MT© Product",
    url: "product",
  },
  {
    linkText: "Industries",
    url: "industries",
  },
  {
    linkText: "Services",
    url: "services",
  },
  {
    linkText: "Implementation",
    url: "implementation",
  },
  {
    linkText: "Contact Us",
    url: "contact",
  },
];
